$black: #050505;
$white: #fefefe;
$white_darken: darken($white, 14%);

.asg-thumbnail {
  .item {
    .index {
      background-color: $white_darken;
      color: $black;
    }
  }
}

.asg-modal.default {

  background-color: $white_darken;

  .highlight {
    background-color: $white;
  }

  .header {
    background-color: $white;
    .title {

    }
    .subtitle {
      background: $white_darken;
      color: $white;
    }
  }

  .help {
    background-color: rgba($black, 0.56);
    color: $white_darken;
  }

  .images {
    .img {
      .details {

      }
    }
  }

  .caption {
    .content {
      background-color: rgba($black, 0.56);
      color: $white_darken;
    }
  }

}

.asg-image.default {

}

.asg-panel.default {
  .item {
    &.selected {
      border-color: $black;
    }
    .index {
      background-color: $white_darken;
      color: $black;
    }
  }
}