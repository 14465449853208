&.rotateTB {

  &.forward .img {

    &.ng-hide-remove, &.ng-hide-add {
      transition: all ease $animspeed;
    }

    &.ng-hide-add.ng-hide-add-active {
      opacity: 0;
      transform: translateY($animsize) rotateX(-$rotate);
    }

    &.ng-hide-add {
      opacity: 1;
      transform: translateY(0) rotateX(0deg);
    }

    &.ng-hide-remove.ng-hide-remove-active {
      opacity: 1;
      transform: translateY(0) rotateX(0deg);
    }

    &.ng-hide-remove {
      opacity: 0;
      transform: translateY(-$animsize) rotateX($rotate);
    }

  }

  &.backward .img {

    &.ng-hide-remove, &.ng-hide-add {
      transition: all ease $animspeed;
    }

    &.ng-hide-add.ng-hide-add-active {
      opacity: 0;
      transform: translateY(-$animsize) rotateX($rotate);
    }

    &.ng-hide-add {
      opacity: 1;
      transform: translateY(0) rotateX(0deg);
    }

    &.ng-hide-remove.ng-hide-remove-active {
      opacity: 1;
      transform: translateY(0) rotateX(0deg);
    }

    &.ng-hide-remove {
      opacity: 0;
      transform: translateY($animsize) rotateX(-$rotate);
    }

  }

}