.img {
  max-width: 100%;
  max-height: 100%;
  margin: auto;
  position: absolute;
  top: 0;
  right: 0px;
  left: 0;
  z-index: 0;
  width: auto;
  height: auto;
  bottom: 0px;
  opacity: 1;

  background-size: auto;
  background-position: 50% 50%;
  background-repeat: no-repeat;

  >.placeholder {
    position: absolute;
    top: 0px;
    right: 0px;
    bottom: 0px;
    left: 0px;
    z-index: auto;
    overflow: hidden;
    background-size: auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    filter: blur(10px);

    &.cover {
      background-size: cover;
    }

    &.contain {
      background-size: contain;
    }

    &.stretch {
      background-size: 100% 100%;
    }

  }

  >.source {
    max-width: 100%;
    max-height: 100%;
    margin: auto;
    display: block;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background-size: auto;
    background-position: 50% 50%;
    background-repeat: no-repeat;
    transition: all ease .5s;
    opacity: 0;

    &.cover {
      background-size: cover;
    }

    &.contain {
      background-size: contain;
    }

    &.stretch {
      background-size: 100% 100%;
    }

  }

  &.loaded {

    background-image: none;

    .source {
      opacity: 1;
    }

  }

}

@import 'transitions/rotateLR';
@import 'transitions/rotateTB';
@import 'transitions/rotateZY';
@import 'transitions/slideLR';
@import 'transitions/slideTB';
@import 'transitions/zlideLR';
@import 'transitions/zlideTB';
@import 'transitions/fadeInOut';
@import 'transitions/zoomIn';
@import 'transitions/zoomOut';
@import 'transitions/zoomInOut';
@import 'transitions/flipX';
@import 'transitions/flipY';

