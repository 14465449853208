&.zlideTB {

  &.forward .img {

    &.ng-hide-remove, &.ng-hide-add {
      transition: all ease $animspeed;
    }

    &.ng-hide-add.ng-hide-add-active {
      opacity: 0;
	  transform: translateY($animsize) scale($scaleOut);
    }

    &.ng-hide-add {
      opacity: 1;
	  transform: translateY(0) scale(1);
    }

    &.ng-hide-remove.ng-hide-remove-active {
      opacity: 1;
	  transform: translateY(0) scale(1);
    }

    &.ng-hide-remove {
      opacity: 0;
	  transform: translateY(-$animsize) scale($scaleOut);
    }

  }

  &.backward .img {

    &.ng-hide-remove, &.ng-hide-add {
      transition: all ease $animspeed;
    }

    &.ng-hide-add.ng-hide-add-active {
      opacity: 0;
	  transform: translateY(-$animsize) scale($scaleOut);
    }

    &.ng-hide-add {
      opacity: 1;
	  transform: translateY(0) scale(1);
    }

    &.ng-hide-remove.ng-hide-remove-active {
      opacity: 1;
	  transform: translateY(0) scale(1);
    }

    &.ng-hide-remove {
      opacity: 0;
	  transform: translateY($animsize) scale($scaleOut);
    }

  }
}
