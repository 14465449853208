$perspective: 1280px;
$animspeed: 0.64s;
$animsize: 14%;
$rotate: 32deg;
$scaleIn: 1.14;
$scaleOut: 0.86;

body.asg-yhidden {
  overflow-y: hidden;
}

@import "scss/asg-image";
@import "scss/asg-modal";
@import "scss/asg-panel";
@import "scss/asg-thumbnail";
@import "scss/asg-info";
@import "scss/asg-control";
@import "scss/theme-default";
@import "scss/theme-darkblue";
@import "scss/theme-darkred";
@import "scss/theme-whitegold";
