$black: #212121;
$white: #f1f1f1;
$white_darken: darken($white, 14%);
$primary: #d4c386;

.asg-thumbnail.whitegold {

  background-color: #d4c386;
  padding: 4px;

  .item {

    margin: 0px 4px 0px 0px;
    opacity: 0.64;

    &.selected {
      opacity: 1;
    }

    &:hover, &.selected {
      .index {
        opacity: 1;
        right: 0px;
      }
    }

    .index {
      background-color: rgba($black, 0.64);
      color: $primary;
      top: 0px;
      right: -10px;
      bottom: 0px;
      opacity: 0;
    }

    background-color: $black;

  }
}

.asg-image.whitegold {

  background-color: $primary;
  color: $white;

  .asg-info {

    background-color: rgba($black, 0.5);
    color: darken($white, 5%);

    a {
      color: darken($white, 5%);
    }

  }

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $white;
    color: $white;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $primary;
    background-color: $white;
  }

}

.asg-modal.whitegold {

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $white;
    color: $white;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $primary;
    background-color: $white;
  }

  background-color: $primary;

  .highlight {

  }

  .header {
    background-color: $primary;
    color: $primary;
    .title {
    }
    .subtitle {
      background: $primary;
      color: $white;
    }
  }

  .help {
    background-color: rgba($primary, 0.77);
    color: $white;
  }

  .images {
    .img {
      .source {
      }
    }
  }

  .caption {
    .content {
      background-color: rgba($primary, 0.56);
      color: $white;
    }
  }

}

.asg-control.whitegold {

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $white;
    color: $white;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $primary;
    background-color: $white;
  }

  background-color: $primary;

}

.asg-info.whitegold {

  background-color: $primary;
  color: $white;

  a {
    color: $white;
    border-bottom: 1px dashed $primary;
    text-decoration: none;

    &:hover {
      text-decoration: none;
      border-bottom: 1px solid $primary;
    }

  }

}

.asg-panel.whitegold {

  .item {

    &.selected {

      border-color: $primary;

      .index {
        background-color: $primary;
        color: $white;
      }

    }

    .index {
      color: $primary;
      top: inherit;
      bottom: 14px;
      font-size: 11px;
      padding: 2px 6px;
    }
  }
}