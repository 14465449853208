$black: #050505;
$white: #fefefe;
$white_darken: darken($white, 14%);
$primary: #30c6d4;

.asg-thumbnail.darkblue {

  background-color: $black;
  padding: 4px 0px 0px 0px;

  .item {

    margin: 0px 4px 0px 0px;
    opacity: 0.64;

    &.selected {
      opacity: 1;
    }

    img {
      height: 42px;
    }

    &:hover, &.selected {
      .index {
        opacity: 1;
        right: 0px;
      }
    }

    .index {
      background-color: rgba($black, 0.64);
      color: $primary;
      top: 0px;
      right: -10px;
      bottom: 0px;
      opacity: 0;
    }

    background-color: $black;

  }
}

.asg-image.darkblue {

  background-color: $black;
  color: $primary;

  .asg-info {

    top: -100px;
    bottom: auto;
    font-size: 12px;
    background-color: rgba($black, 0);
    transition: all 0.25s;

    a {
      color: $primary;
      border-bottom: 1px dashed $primary;
      text-decoration: none;

      &:hover {
        text-decoration: none;
        border-bottom: 1px solid $primary;
      }

    }

  }

  &:hover .asg-info {
    background-color: rgba($black, 0.77);
    top: 0;
  }

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $black;
    background-color: $primary;
  }

}

.asg-modal.darkblue {

  .frame {
    margin: 0;
  }

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $black;
    background-color: $primary;
  }

  background-color: $black;

  .header {
    background-color: $black;
    color: $primary;
    .title {
      text-transform: uppercase;
    }
    .subtitle {
      background: $primary;
      color: $black;
    }
  }

  .help {
    background-color: rgba($black, 0.77);
    color: $primary;
  }

  .caption {
    .content {
      background-color: rgba($black, 0.64);
      color: $primary;
      padding: 0px;
      line-height: 32px;
    }
  }

  .asg-thumbnail {

    background-color: rgba($black, 0.77);

    .item {
      img {
        height: 77px;
      }
    }
  }

}

.asg-control.darkblue {

  .btn {
    -webkit-transition: all .3s ease-in-out;
    transition: all .3s ease-in-out;
    border-radius: 0;
    background-image: none;
    text-shadow: none;
  }

  .btn-default {
    border: 1px solid $primary;
    color: $primary;
    background-color: transparent;
  }

  .btn-default:focus, .btn-default:hover {
    border: 1px solid $primary;
    outline: none;
    color: $black;
    background-color: $primary;
  }

  background-color: $black;

}

.asg-info.darkblue {

  background-color: $black;
  color: $primary;

}

.asg-panel.darkblue {

  background-color: $black;

  .item {

    background-color: $black;

    &.selected {
      border-color: $black;
    }
    .index {
      background-color: rgba($black, 0.56);
      color: $primary;
    }
  }
}